import React from 'react';
import ReactDOM from 'react-dom';

import 'core-js/stable';
import 'react-app-polyfill/ie11';
import 'regenerator-runtime/runtime';
import smoothscroll from 'smoothscroll-polyfill';

import './index.scss';
import * as serviceWorker from './shared/utilities/serviceWorker';

import { App } from './app';

if (process.env.REACT_APP_DEPLOYMENT_NAME && process.env.REACT_APP_DEPLOYMENT_NAME.length > 0) {
  import('./shared/utilities/devInfo');
}

smoothscroll.polyfill();

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
